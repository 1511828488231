import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="bg-brandDark p-4 pb-8 text-center text-white">
      <p>© 2023 Lower Towns, LLC</p>
      <p>Chattanooga, TN</p>
      <p>
        <a href="mailto:hello@lowertowns.com" className="underline">
          hello@lowertowns.com
        </a>
      </p>
    </footer>
  );
};

export default Footer;
