import React from "react";

interface Props {
  header: string;
  backgroundFlip?: boolean;
  children: React.ReactNode;
}

const ContentSection: React.FC<Props> = ({
  header,
  backgroundFlip,
  children,
}) => {
  return (
    <section
      className={`p-4 flex justify-center ${
        backgroundFlip ? "bg-secondary" : "bg-white"
      } w-full`}
    >
      <div className="md:w-2/3 lg:w-1/2">
        <h1 className="text-xl font-bold mb-4 text-center">{header}</h1>
        <p>{children}</p>
      </div>
    </section>
  );
};

export default ContentSection;
