import React, {useState} from "react";

const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    setFormData({
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
    });
    setShowConfirmation(false);
  };

  const sendContactForm = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!formData.name || !formData.email || !formData.message) {
      alert("Name, Email, and Message are required fields.");
      return;
    }

    try {
      const response = await fetch("/api/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setShowConfirmation(true);
      } else {
        console.log("Error submitting form.");
      }
    } catch (error) {
      console.error("There was an error submitting the form.", error);
    }
  };

  return (
    <section
      className="p-4 flex items-center justify-center bg-secondary"
      id="contactForm"
    >
      <div className="md:w-2/3 lg:w-1/2">
        <h1 className="text-xl font-bold mb-4 text-center">Contact Us</h1>
        <p className="mb-4 text-center">
          You can also reach us directly at{" "}
          <a href="tel:4235555555" className="text-blue-600">
            (423) 815-9886
          </a>{" "}
          via call or text.
        </p>
        <form onSubmit={sendContactForm}>
          <div className="mb-4">
            <label className="block mb-2">Name</label>
            <input
              type="text"
              name="name"
              required
              value={formData.name}
              onChange={handleChange}
              className="p-2 w-full rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Email</label>
            <input
              type="email"
              name="email"
              required
              value={formData.email}
              onChange={handleChange}
              className="p-2 w-full rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Phone Number (Optional)</label>
            <input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              className="p-2 w-full rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Message</label>
            <textarea
              name="message"
              placeholder="Tell us about your property..."
              required
              value={formData.message}
              onChange={handleChange}
              className="p-2 w-full rounded h-32"
            ></textarea>
          </div>
          <button
            type="submit"
            className="bg-primary text-white text-xl p-4 rounded w-full"
          >
            Send
          </button>
        </form>
        {showConfirmation && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center text-center">
            <div className="bg-white p-8 w-80 md:w-1/4 rounded shadow-md border border-gray-300">
              <h2 className="text-xl mb-4">Thanks!</h2>
              <p>We will get back out as soon as we get the chance.</p>
              <button
                className="mt-4 bg-primary text-white p-2 rounded"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default ContactForm;
