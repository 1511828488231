import ContactForm from "./components/ContactForm";
import ContentSection from "./components/ContentSection";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Hero from "./components/Hero";

function App() {
  return (
    <div className="font-sans min-h-screen">
      <Header />
      <Hero />

      <ContentSection header="Who We Are">
        <p>
          We are a family-owned real estate business in Chattanooga, Tennessee.
          We are natives to the Tennessee Valley and know the greater
          Chattanooga area like the backs of our hands.
        </p>
        <br />
        <p>
          We are NOT real estate agents or wholesalers. We are investors—the
          solution for homeowners who want to sell their homes or properties off
          market. We work quickly and effectively to get you cash in hand for
          your home in as little as 14 days.
        </p>
      </ContentSection>
      <ContentSection header="What We Do" backgroundFlip>
        <p>
          As investors, we strive to make the selling process as smooth and
          painless for you as possible.
        </p>
        <br />
        We know there are a thousand and one possible reasons you need to sell
        your home:
        <ul className="list-disc pl-5">
          <li>Avoiding foreclosure</li>
          <li>Relocating to care for a sick loved one</li>
          <li>Downsizing</li>
          <li>Offloading a vacant property</li>
          <li>Going through a divorce</li>
          <li>Inheriting a property you can’t manage</li>
          <li>Losing your job</li>
          <li>…and many other situations</li>
        </ul>
        <br />
        <p>
          Tell us about your home or property We’ll evaluate if it fits our
          criteria We give you an all-cash offer - no obligation We close and
          you get CASH
        </p>
      </ContentSection>
      <ContentSection header="Why Us?">
        Explanation about the advantages of choosing Lower Towns Real Estate.
      </ContentSection>

      <ContactForm />

      <Footer />
    </div>
  );
}

export default App;
