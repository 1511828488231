import React from "react";

const Header: React.FC = () => {
  const handleContactClick = () => {
    const element = document.getElementById("contactForm");
    if (element) {
      element.scrollIntoView({behavior: "smooth"});
    }
  };

  return (
    <header className="bg-primary p-4 flex text-white">
      <div className="hidden md:flex md:flex-1"></div>
      <img
        src="/whitelogonobg.svg"
        alt="Lower Towns Real Estate"
        className="w-3/5 md:w-1/5 mx-auto md:mx-0"
      />
      <div className="flex-1 flex justify-end">
        <button
          className="m-2 rounded font-semibold border-2 border-white p-2 hover:bg-primary hover:text-white transition duration-300"
          onClick={handleContactClick}
        >
          Contact Us
        </button>
      </div>
    </header>
  );
};

export default Header;
