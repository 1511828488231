import React from "react";

const Hero: React.FC = () => {
  return (
    <div
      className="flex items-center justify-center h-[200px] md:h-[600px] bg-center bg-cover"
      style={{backgroundImage: `url('/hero.jpg')`}}
    >
      <div className="p-4 bg-opaque rounded-lg text-white text-center w-2/3 lg:w-1/2">
        <h1 className="text-xl md:text-2xl font-bold mb-2">
          Sell your home without the hassle
        </h1>
        <p className="text-xs md:text-sm">
          Get cash in hand for an off-market deal in Chattanooga, Tennessee
        </p>
      </div>
    </div>
  );
};

export default Hero;
